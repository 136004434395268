import { Component, Prop, Vue } from 'vue-property-decorator'

//components
import ECard from '@/components/esic/eCard/ECard.vue';

//wrappers
import {
	WColumn,
	WColumns,
	SizedBox,
	WLine,
	wIcon,
	SmoothDropdown
} from '@/components/wrappers';

@Component({
	components:{
		WColumn,
		WColumns,
		SizedBox,
		WLine,
		ECard,
		wIcon,
		SmoothDropdown
	}
})
export default class EContent extends Vue {
	// @Prop({required: true}) portalName!: string
}

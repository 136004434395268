import { PortalName } from "@/config"

export const Variables = {
	links:{
		transparency: "",
		PMA: "",
	},
	portalName:"Prefeitura Municipal de Laranja da Terra",
	portalSite:"www.prefeitura.es.gov.br",
	address:"",
	cityHallName: "Laranja da Terra",
	htmlTextLaw:`A 
			<a
				target="_blank"
				href="http://www.planalto.gov.br/ccivil_03/_ato2011-2014/2011/lei/l12527.htm"
				>Lei Federal nº 12.527/2011</a
			>
			regulamentou o direito constitucional de acesso a informação previsto
			no inciso XXXIII do art. 5o, no inciso II do 3º do art. 37 e no 2º
			do art. 216 da Constituição Federal. Neste canal qualquer pessoa
			física ou jurídica poderá encaminhar pedidos de acesso à qualquer
			informação pública produzida ou sob a guarda da ${PortalName}.
	`
}
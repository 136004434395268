import { Component, Vue } from "vue-property-decorator";

import { Variables } from "@/config/variables";

//esic components
import EContent from "@/components/esic/eContent/EContent.vue";

//wrappers
import {
  WColumn,
  WColumns,
  SizedBox,
  WLine,
  wIcon,
  SmoothDropdown,
  WButton,
  WImage,
} from "@/components/wrappers";

@Component({
  components: {
    WLine,
    wIcon,
    WImage,
    WColumn,
    WButton,
    WColumns,
    SizedBox,
    EContent,
    SmoothDropdown,
  },
})
export default class Estatisticacas extends Vue {
  variables = Variables;

  estatisticas: any = {
    2021: [
      {
        label: "e-sic",
        class: "is-esic",
        total: 3,
        resolvido: 3,
        andamento: 0,
      },
      {
        label: "Denúncia",
        class: "is-denuncia",
        total: 8,
        resolvido: 8,
        andamento: 0,
      },
      {
        label: "Solicitação",
        class: "is-esic",
        total: 1,
        resolvido: 1,
        andamento: 0,
      },
      {
        label: "Elogio",
        class: "is-elogio",
        total: 2,
        resolvido: 2,
        andamento: 0,
      },
      {
        label: "Reclamação",
        class: "is-reclamacao",
        total: 3,
        resolvido: 3,
        andamento: 0,
      },
      {
        label: "Sugestão",
        class: "is-sugestao",
        total: 0,
        resolvido: 0,
        andamento: 0,
      },
    ],
    2022: [
      {
        label: "e-sic",
        class: "is-esic",
        total: 0,
        resolvido: 0,
        andamento: 0,
      },
      {
        label: "Denúncia",
        class: "is-denuncia",
        total: 21,
        resolvido: 21,
        andamento: 0,
      },
      {
        label: "Solicitação",
        class: "is-esic",
        total: 4,
        resolvido: 4,
        andamento: 0,
      },
      {
        label: "Elogio",
        class: "is-elogio",
        total: 0,
        resolvido: 0,
        andamento: 0,
      },
      {
        label: "Reclamação",
        class: "is-reclamacao",
        total: 15,
        resolvido: 15,
        andamento: 0,
      },
      {
        label: "Sugestão",
        class: "is-sugestao",
        total: 0,
        resolvido: 0,
        andamento: 0,
      },
    ],
    2023: [
      {
        label: "e-sic",
        class: "is-esic",
        total: 0,
        resolvido: 0,
        andamento: 0,
      },
      {
        label: "Denúncia",
        class: "is-denuncia",
        total: 24,
        resolvido: 24,
        andamento: 0,
      },
      {
        label: "Solicitação",
        class: "is-esic",
        total: 0,
        resolvido: 0,
        andamento: 0,
      },
      {
        label: "Elogio",
        class: "is-elogio",
        total: 0,
        resolvido: 0,
        andamento: 0,
      },
      {
        label: "Reclamação",
        class: "is-reclamacao",
        total: 11,
        resolvido: 11,
        andamento: 0,
      },
      {
        label: "Sugestão",
        class: "is-sugestao",
        total: 1,
        resolvido: 1,
        andamento: 0,
      },
    ],
    2024: [
      {
        label: "e-sic",
        class: "is-esic",
        total: 0,
        resolvido: 0,
        andamento: 0,
      },
      {
        label: "Denúncia",
        class: "is-denuncia",
        total: 9,
        resolvido: 3,
        andamento: 6,
      },
      {
        label: "Solicitação",
        class: "is-esic",
        total: 0,
        resolvido: 0,
        andamento: 0,
      },
      {
        label: "Elogio",
        class: "is-elogio",
        total: 0,
        resolvido: 0,
        andamento: 0,
      },
      {
        label: "Reclamação",
        class: "is-reclamacao",
        total: 13,
        resolvido: 3,
        andamento: 10,
      },
      {
        label: "Sugestão",
        class: "is-sugestao",
        total: 1,
        resolvido: 1,
        andamento: 0,
      },
    ],
  };
}
